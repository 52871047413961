import React from 'react';

import { layout } from './background-repeat.module.scss';
import { grid } from '../styles/grid.module.scss';
import bg01 from '../assets/images/bg-repeat-01.webp';
import bg02 from '../assets/images/bg-repeat-02.webp';
import bg03 from '../assets/images/bg-repeat-03.webp';
import bg04 from '../assets/images/bg-repeat-04.webp';

interface IBackgroundRepeatProps {
    className?: string;
    isMainGrid?: boolean;
    type?: 1 | 2 | 3 | 4;
}

const BackgroundRepeat: React.FC<IBackgroundRepeatProps> = ({
    className = '',
    isMainGrid = true,
    type,
    children,
}) => {
    return (
        <div
            className={`${layout} ${isMainGrid ? grid : ''} ${className}`}
            style={{
                backgroundImage: `url(${getBackgroundImage(type)})`,
            }}
        >
            {children}
        </div>
    );
};

function getBackgroundImage(type: IBackgroundRepeatProps['type']): string {
    if (type === 1) return bg01;
    if (type === 2) return bg02;
    if (type === 3) return bg03;
    if (type === 4) return bg04;
    return '';
}

export default BackgroundRepeat;
